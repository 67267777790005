import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["dynamicselect"];
  static values = {
    url: String,
    param: String,
    productId: Number,
    assignedAttributes: String
  }

  async change(e) {
    let params = new URLSearchParams();
    params.append(this.paramValue, e.target.selectedOptions[0].value);
    params.append("target", this.dynamicselectTarget.id);

    const response = await fetch(`${this.urlValue}?${params}`, {
      headers: {
        "Accept": "application/json"
      }
    });

    if (response.ok) {
      const data = await response.json();
      this.updateOptions(data);
    }
  }

  updateOptions(data) {
    this.dynamicselectTarget.innerHTML = "";
    const assignedAttributes = this.assignedAttributesValue.split(',').map(Number);
    let filtered_options = data.options.filter(option => !assignedAttributes.includes(option.value));
    let sorted_options = filtered_options.sort((a, b) => a.text.localeCompare(b.text));

    sorted_options.forEach(option => {
      const opt = document.createElement("option");
      opt.value = option.value;
      opt.text = option.text;
      this.dynamicselectTarget.appendChild(opt);
    });
  }
}